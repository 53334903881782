import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    "audioPlayer"
  ]
  static values = {
    playing: { type: String },
    bpm: { type: Number, default: 90 },
    volume: { type: Number, default: 0.5 },
    muted: { type: Boolean, default: false }
  }

  connect() {
    this.nextNote = false
  }

  audioPlayerTargetConnected(audioPlayer) {
    const audio = audioPlayer.querySelector('audio')
    audio.volume = this.volumeValue
  }

  playAudio(e) {
    let audioPlayer = e.target
    if (!e.target.classList.contains('audio-player')) {
      audioPlayer = e.target.closest('.audio-player')
    }
    const audio = audioPlayer.querySelector('audio')

    if (this.playingValue && this.playingValue != audioPlayer.id) {
      const currentlyPlaying = document.getElementById(this.playingValue)
      if (currentlyPlaying) {
        currentlyPlaying.querySelector('audio').pause()
      }
    }

    if (audio.paused) {
      audio.play()
    }
    audioPlayer.classList.add('playing')
    this.playingValue = audioPlayer.id
    if (audioPlayer.dataset.bpm) {
      this.bpmValue = audioPlayer.dataset.bpm
    }
  }

  toggleAudio(e) {
    let audioPlayer = e.target
    if (!e.target.classList.contains('audio-player')) {
      audioPlayer = e.target.closest('.audio-player')
    }
    const audio = audioPlayer.querySelector('audio')

    if (audioPlayer.classList.contains('playing')) {
      audio.pause()
    } else {
      audio.play()
    }
  }

  pauseAudio(e) {
    let audioPlayer = e.target
    if (!e.target.classList.contains('audio-player')) {
      audioPlayer = e.target.closest('.audio-player')
    }
    const audio = audioPlayer.querySelector('audio')
    audioPlayer.classList.remove('playing')
    if (!document.querySelector('.audio-player.playing')) {
      this.playingValue = ''
    }
  }

  spawnNote() {
    if (this.playingValue) {
      const currentlyPlaying = document.getElementById(this.playingValue)
      if (currentlyPlaying) {
        const dog = currentlyPlaying.querySelector('.audio-player__dog')
        if (dog) {
          if (currentlyPlaying.classList.contains('playing-alt')) {
            currentlyPlaying.classList.remove('playing-alt')
          } else {
            currentlyPlaying.classList.add('playing-alt')
          }
          const note = document.createElement('img')
          note.src = '/assets/images/note-small.png'
          note.classList.add('absolute', 'animate-note', 'z-30', 'pixel')
          note.width = 18
          note.height = 18
          const curve = Math.floor(Math.random() * 10) - 10
          const finalX = Math.floor(Math.random() * 10) - 5
          const finalY = Math.floor(Math.random() * 10) - 40
          note.setAttribute('style', `top: ${Math.floor(Math.random() * 10) - 10}; left: ${Math.floor(Math.random() * 30) + 40}%; offset-path: path('M 0 0 C ${curve} ${curve / 2} ${finalX} ${finalY} ${finalX} ${finalY}`)
          dog.appendChild(note)
          setTimeout(function() {
            note.remove()
          }, 1000)

          if (currentlyPlaying.dataset.bpm) {
            this.bpmValue = currentlyPlaying.dataset.bpm
          }
          this.nextNote = setTimeout(this.spawnNote.bind(this), ((60 / this.bpmValue) * 1000))
        }
      }
    }
  }

  updateVolume(e) {
    if (e.target.muted) {
      this.mutedValue = true
      this.volumeValue = 0
    } else {
      this.mutedValue = false
      this.volumeValue = e.target.volume
    }
    for (const audioPlayer of this.audioPlayerTargets) {
      const audio = audioPlayer.querySelector('audio')
      audio.volume = this.volumeValue
    }
  }

  stopNotes() {
    if (this.nextNote) {
      clearTimeout(this.nextNote)
      this.nextNote = false
    }
  }

  syncNotes() {
    this.stopNotes()
    this.spawnNote()
  }
}
