import "$styles/index.css"
import { Application } from "@hotwired/stimulus"

import "lazysizes"

import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox/fancybox.css"
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
    display: {
      left: [],
      middle: [],
      right: ["close"],
    },
  }
})

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

window.Stimulus = Application.start()

import controllers from "./controllers/**/*.{js,js.rb}"
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename.replace("./controllers/", "")
      .replace(/[_-]controller\..*$/, "")
      .replace(/_/g, "-")
      .replace(/\//g, "--")

    Stimulus.register(identifier, controller.default)
  }
})

document.body.className = document.body.className.replace("no-js","")
